<template>
    <div class="event-list-view">
        <h1>
            Список событий
        </h1>

        <event-list />
    </div>
</template>

<script>
import EventList from '../components/EventList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';

export default {
    name: 'EventListView',

    components: {
        EventList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'События',
                    route: ROUTES.EVENT.LIST
                }
            ];
        }
    },

    methods: {}
};
</script>

<style lang="scss">
    @import "@vars";

</style>
