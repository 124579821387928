<template>
    <div class="event-list-view">
        <h1>
            Список онлайн-событий
        </h1>

        <online-event-list />
    </div>
</template>

<script>
import OnlineEventList from '../components/OnlineEventList';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';

export default {
    name: 'OnlineEventListView',

    components: {
        OnlineEventList
    },

    mixins: [
        breadcrumbs
    ],

    computed: {
        $breadcrumbs() {
            return [
                {
                    title: 'Онлайн события',
                    route: ROUTES.ONLINE_EVENT.LIST
                }
            ];
        }
    },

    methods: {}
};
</script>

<style lang="scss">
    @import "@vars";

</style>
