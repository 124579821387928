import moment from 'moment';

/**
 * @param {String} start
 * @param {String} end
 * @param {String} [format]
 * @param {String} [separator]
 * @returns {string}
 */
export default (start, end, format = 'DD MMMM', separator = ' - ') => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    return `${startMoment.format(format)}${separator}${endMoment.format(format)}`;
};
