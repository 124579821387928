<template>
    <div
        v-loading="isLoading"
        class="event-list"
    >
        <el-table
            :data="preparedData"
            style="width: 100%"
        >
            <el-table-column
                :width="400"
                label="Название"
            >
                <template slot-scope="scope">
                    <router-link :to="getCardRoute(scope.row)">
                        {{ scope.row.name }}
                    </router-link>
                    <i
                        class="el-icon-edit-outline event-list__icon event-list__edit"
                        @click="edit(scope.row)"
                    />
                </template>
            </el-table-column>

            <el-table-column
                prop="registration"
                label="Регистрация"
            />

            <el-table-column
                prop="dates"
                label="Пройдет"
            />

            <el-table-column
                prop="participants_registered"
                :width="70"
                label="Гостей"
            />

            <el-table-column
                :width="60"
                align="center"
            >
                <template slot-scope="scope">
                    <i
                        class="el-icon-delete event-list__icon event-list__remove"
                        @click="tryRemove(scope)"
                    />
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { Event } from '@/api';
import { ROUTES } from '@/enums';
import datesFromTo from '@/utils/datesFromTo';

export default {
    name: 'EventList',

    data() {
        return {
            /** @type {EventResource[]} */
            events: [],
            filter: {},
            isLoading: false
        };
    },

    computed: {
        preparedData() {
            /**
             * @param {EventResource} x
             * @returns {{id: *, name: *, registration: string, dates: string, guests: number}}
             */
            const mapper = x => ({
                id: x.attributes.id,
                name: x.attributes.title,
                registration: datesFromTo(x.attributes.registration_start_at, x.attributes.registration_end_at),
                dates: datesFromTo(x.attributes.start_at, x.attributes.end_at),
                participants_registered: x.attributes.participants_registered
            });
            return this.events.map(mapper);
        }
    },

    watch: {
        filter() {
            this._loadData();
        }
    },

    mounted() {
        this._loadData();
    },

    methods: {
        tryRemove({ row, $index }) {
            this.$confirm('Вы уверены, что хотите удалить событие?')
                .then(() => this._remove({ row, index: $index }))
                .catch(() => {
                });
        },

        getCardRoute(row) {
            return {
                name: ROUTES.EVENT.CARD.PARTICIPANTS,
                params: {
                    eventId: row.id
                }
            };
        },

        _remove({ row, index }) {
            /**
             * @param {EventResource} event
             */
            const onResponse = event => {
                this.$message({
                    message: 'Событие удалено',
                    type: 'success'
                });
                this.events.splice(index, 1);
            };
            const onError = e => {
                this.$message.error('Не удалось удалить событие');
            };
            Event.remove({ eventId: row.id })
                .then(onResponse)
                .catch(onError);
        },

        edit(row) {
            this.$router.push({
                name: ROUTES.EVENT.EDIT,
                params: {
                    eventId: row.id
                }
            });
        },

        _loadData() {
            const filter = this.filter;
            this.isLoading = true;
            Event.getAllEvents(filter)
                .then(eventResources => {
                    this.events = eventResources;
                })
                .catch(() => {
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.event-list {
    max-width: 1000px;

    &__icon {
        font-size: 1.2em;
        cursor: pointer;
        visibility: hidden;
    }

    &__edit {
        color: #0486fe;
        margin-left: 10px;
    }

    &__remove {
        color: #eb2929;
    }

    .el-table__row {
        &:hover {
            .event-list__icon {
                visibility: visible;
            }
        }
    }
}
</style>
